import React, { useRef } from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { scrollTo } from "../../../helpers/scrollTo"

export const withHero = Component => ({ name = "Hero", analytics = {}, items, cols, ratio, showExploreButton, exploreButtonText, enableLinkOnVideo }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()

  const formattedRatio = ratio?.width && ratio?.height ? `${ratio.width}/${ratio.height}` : undefined

  const heroRef = useRef(null)

  const handleClick = () => {
    scrollTo(heroRef?.current?.getBoundingClientRect().height)
  }

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...analytics })}>
      <Component
        heroRef={heroRef}
        items={items}
        cols={cols}
        ratio={formattedRatio}
        showExploreButton={showExploreButton}
        exploreButtonText={exploreButtonText}
        handleClick={handleClick}
        enableLinkOnVideo={enableLinkOnVideo}
      />
    </VisibilitySensor>
  )
}
