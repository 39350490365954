import React from "react"

export const withTextButtonsGrid = Component => ({ name = "TextButtonsGrid", content, layout = "normal" }) => {
  const {
    align,
    buttons,
    mobileButtons,
    buttonMarginTop,
    description,
    mobileDescription,
    descriptionMarginTop,
    hideOnMobile,
    hideDescriptionOnMobile,
    title,
    mobileTitle,
    maxWidth,
    buttonsPadding,
    horizontalPaddingAround,
    verticalPaddingAround,
  } = content || {}

  Component.displayName = name
  return (
    <Component
      align={align}
      buttons={buttons}
      mobileButtons={mobileButtons?.length > 0 ? mobileButtons : buttons}
      buttonMarginTop={buttonMarginTop}
      description={description}
      mobileDescription={mobileDescription}
      descriptionMarginTop={descriptionMarginTop}
      hideOnMobile={hideOnMobile}
      hideDescriptionOnMobile={hideDescriptionOnMobile}
      title={title}
      mobileTitle={mobileTitle}
      maxWidth={maxWidth}
      horizontalPaddingAround={horizontalPaddingAround}
      verticalPaddingAround={verticalPaddingAround}
      layout={layout}
      buttonsPadding={buttonsPadding}
    />
  )
}
