import React from "react"
import { withTextButtonsGrid } from "./withTextButtonsGrid"
import { Wrapper, Title, Description, Buttons, Desktop, Mobile, Button } from "./TextButtonsGridStyles"
import { ButtonLink } from "../ButtonLink/ButtonLink"

export const TextButtonsGrid = withTextButtonsGrid(
  ({
    align,
    buttons,
    mobileButtons,
    buttonMarginTop,
    description,
    mobileDescription,
    descriptionMarginTop,
    hideOnMobile,
    hideDescriptionOnMobile,
    maxWidth,
    horizontalPaddingAround,
    verticalPaddingAround,
    title,
    mobileTitle,
    layout,
    buttonsPadding,
  }) =>
    title?.length > 0 || description?.length > 0 || buttons?.length > 0 ? (
      <Wrapper maxWidth={maxWidth} horizontalPaddingAround={horizontalPaddingAround} verticalPaddingAround={verticalPaddingAround}>
        <Desktop>
          <Title>{title}</Title>
          <Description descriptionMarginTop={descriptionMarginTop}>{description}</Description>
          <Buttons align={align} buttonMarginTop={buttonMarginTop}>
            {buttons?.map(button => (
              <Button buttonsPadding={buttonsPadding} key={button?._key} link={button?.link} style={button?.style} size={button?.size} uppercase={button?.uppercase} />
            ))}
          </Buttons>
        </Desktop>
        {hideOnMobile ? null : (
          <Mobile>
            <Title>{mobileTitle || title}</Title>
            <Description descriptionMarginTop={descriptionMarginTop} hideDescriptionOnMobile={hideDescriptionOnMobile}>
              {mobileDescription || description}
            </Description>
            <Buttons align={align} buttonMarginTop={buttonMarginTop}>
              {mobileButtons?.map(button => (
                <Button buttonsPadding={buttonsPadding} key={button?._key} link={button?.link} style={button?.style} size={button?.size} uppercase={button?.uppercase} />
              ))}
            </Buttons>
          </Mobile>
        )}
      </Wrapper>
    ) : null
)
