import React from "react"

import { useSale } from "../../hooks/useSale"

export const withSection = Component => ({ name = "Section", children, appearance }) => {
  const { hide, maxWidth, horizontalSpacing, verticalSpacing, borderTop, borderBottom, promotion, promotionVisibility, hideDesktop, hideMobile, topPaddingSpacing, bottomPaddingSpacing } = appearance || {}

  const { getSaleVisibility } = useSale()

  const visibility = hide === "hide" ? "hide" : getSaleVisibility(promotion, promotionVisibility)

  Component.displayName = name
  return visibility !== "hide" ? (
    <Component
      maxWidth={maxWidth}
      horizontalSpacing={horizontalSpacing}
      verticalSpacing={verticalSpacing}
      borderTop={borderTop}
      borderBottom={borderBottom}
      hideDesktop={hideDesktop}
      hideMobile={hideMobile}
      topPaddingSpacing={topPaddingSpacing}
      bottomPaddingSpacing={bottomPaddingSpacing}
    >
      {children}
    </Component>
  ) : null
}
