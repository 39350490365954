import React, { lazy } from "react"

import { useCore } from "../../hooks/useCore"
import { Section } from "./Section"
import { Hero } from "./Hero/Hero"

export const withSections = Component => ({ name = "Sections", page, ...props }) => {
  const {
    helpers: { ErrorBoundary, isBrowser, isDomReady },
  } = useCore()



  const heroSection = page?.content?.[0]?._type === "sectionHero" ? page?.content?.[0] : false
  const restSection = heroSection ? page?.content?.slice(1) : page?.content

  const sections = restSection?.map(section => {
    const name = section?._type?.replace("section", "")

    const SectionComponent = isBrowser && lazy(() => import(`./${name}/${name}`))

    return SectionComponent
      ? {
        ...section,
        component: () => (
          <ErrorBoundary>
            <SectionComponent {...props} {...section} />
          </ErrorBoundary>
        ),
      }
      : undefined
  })

  Component.displayName = name
  return (
    <>
      {heroSection && (
        <Section appearance={heroSection.appearance}>
          <Hero {...props} {...heroSection} />
        </Section>
      )}
      {isDomReady && <Component {...props} content={sections} />}
    </>
  )
}
