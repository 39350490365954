import React from "react"

import { withHero } from "./withHero"
import { Outer, Wrapper, ExploreWrapper, Explore } from "./HeroStyles"
import { HeroCard } from "./Card/HeroCard"
import { Icon } from "../../Icon/Icon"

export const Hero = withHero(({ heroRef, items, ratio, showExploreButton, exploreButtonText, handleClick, enableLinkOnVideo }) => (
  <Outer ref={heroRef}>
    <Wrapper items={items}>
      {items?.map(item => (
        <HeroCard key={item._key} ratio={ratio} enableLinkOnVideo={enableLinkOnVideo} {...item} />
      ))}
    </Wrapper>
    {showExploreButton === "show" ? (
      <ExploreWrapper onClick={handleClick}>
        <Explore>{exploreButtonText}</Explore>
        <Icon name={"chevronDown"} />
      </ExploreWrapper>
    ) : null}
  </Outer>
))

export default Hero
