import React from "react"
import { withSection } from "./withSection"

import { SectionWrapper } from "./SectionStyles"

export const Section = withSection(({ children, maxWidth, horizontalSpacing, verticalSpacing, borderTop, borderBottom, hideDesktop, hideMobile, topPaddingSpacing, bottomPaddingSpacing }) => (
  <SectionWrapper
    maxWidth={maxWidth}
    horizontalSpacing={horizontalSpacing}
    verticalSpacing={verticalSpacing}
    borderTop={borderTop}
    borderBottom={borderBottom}
    hideDesktop={hideDesktop}
    hideMobile={hideMobile}
    topPaddingSpacing={topPaddingSpacing}
    bottomPaddingSpacing={bottomPaddingSpacing}
  >
    {children}
  </SectionWrapper>
))
