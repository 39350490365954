import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { withButtonLink } from "./withButtonLink"
import { StyledButton, UnderlinedButton } from "../Styled/Button"
import {Icon} from "../Icon/Icon";

export const ButtonLink = withButtonLink(({ className, link, style, size, wide, uppercase }) =>
  style === "underlinedLink" || style === "underlinedLinkWhite" ? (
    <UnderlinedButton
      className={className}
      as={link?.url && (link?.isExternal || link?.external ? `a` : GatsbyLink)}
      href={(link?.isExternal || link?.external) && link?.url}
      target={link?.external && `_blank`}
      title={link?.title}
      to={!link?.isExternal && link?.url}
      colour={style}
      size={size}
    >
      <span>{link?.title}</span>
    </UnderlinedButton>
  ) : (
    <StyledButton
      className={className}
      as={link?.url && (link?.isExternal ? `a` : GatsbyLink)}
      href={link?.isExternal && link?.url}
      target={link?.external && `_blank`}
      title={link?.title}
      to={!link?.isExternal && link?.url}
      colour={style || "default"}
      size={size}
      wide={wide}
      uppercase={uppercase}
    >
      {link?.title}
      {style === "blackArrowIcon" || style === "whiteArrowIcon" && <Icon name={"chevronRight"} width={6} />}
    </StyledButton>
  )
)
