import { css } from "@emotion/react"
import tw, { styled } from "twin.macro"
import { StyledButton } from "../../../Styled/Button"

export const Outer = tw.div`
  relative w-full
`

export const Inner = styled.div`
  ${tw`absolute z-0 inset-0 w-full h-full flex items-center justify-center`}
  ${({ playing }) => (playing ? tw`hidden` : null)}
`

export const Content = styled.div`
  ${({ top, bottom, right, left }) => {
    if (top || bottom || right || left) {
      return tw`absolute transform`
    }
  }}
  ${({ top }) => {
    return top
      ? css`
          top: ${top}%;
        `
      : null
  }}
    ${({ top }) => {
      return top ? tw`-translate-y-1/2` : null
    }}
  ${({ bottom }) => {
    return bottom
      ? css`
          bottom: ${bottom}%;
        `
      : null
  }}
  ${({ bottom }) => {
    return bottom ? tw`translate-y-1/2` : null
  }}
  ${({ left }) => {
    return left
      ? css`
          left: ${left}%;
        `
      : null
  }}
  ${({ left }) => {
    return left ? tw`-translate-x-1/2` : null
  }}
  ${({ right }) => {
    return right
      ? css`
          right: ${right}%;
        `
      : null
  }}
    ${({ right }) => {
      return right ? tw`translate-x-1/2` : null
    }}
`

export const Buttons = styled.div`
  ${tw`flex flex-row`}
  ${({ align }) => {
    if (align === "left") return tw`justify-start`
    if (align === "right") return tw`justify-end`
    return tw`justify-center`
  }}
  ${({ marginTop }) => {
    return marginTop
      ? css`
          margin-top: ${marginTop}%;
        `
      : null
  }}
`
export const Button = styled(StyledButton)`
  ${tw`mx-1 flex items-center`}
  svg {
    ${tw`ml-1`}
  }
`

export const SideText = styled.div`
  ${tw`text-white absolute bottom-2 lg:bottom-0 lg:top-1/2 right-1 lg:right-0 transform lg:-translate-y-1/2 font-serif`}
  writing-mode: vertical-rl;
  text-orientation: mixed;
`