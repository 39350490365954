import React, { useState } from "react"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withHeroCard = Component => ({ name = "HeroCard", buttons, link, image, enableLinkOnVideo, ratio = undefined, className = "", ...props }) => {
  const { linkResolver } = useRoutes()

  const formattedButtons = buttons?.map(button => linkResolver(button))
  const formattedLink = linkResolver(link)
  const isLinkable = enableLinkOnVideo ? true : image?.desktopVideo?.length > 0 ? false : formattedLink?.url ? true : false
  const [playing, setPlaying] = useState(false)

  Component.displayName = name
  return (
    <Component
      link={formattedLink}
      buttons={formattedButtons}
      playing={playing}
      setPlaying={setPlaying}
      image={image}
      isLinkable={isLinkable}
      ratio={ratio}
      className={className}
      {...props}
    />
  )
}
