import React from "react"
import { Link } from "gatsby"

import { withHeroCard } from "./withHeroCard"
import { ImageVideo } from "../../../ImageVideo/ImageVideo"
import { Outer, Inner, Content, Buttons, Button, SideText } from "./HeroCardStyles"
import { StyledContainer } from "../../../Styled/Container"
import { TextButtonsGrid } from "../../../TextButtonsGrid/TextButtonsGrid"
import { RichText } from "../../../RichText/RichText"
import { Icon } from "../../../Icon/Icon"

export const HeroCard = withHeroCard(
  ({ isLinkable, link, content, buttons, image, align, marginTop, top, bottom, left, right, ratio, playing, setPlaying, sideText, className }) => (
    <Outer className={className} as={isLinkable ? Link : null} to={link?.url} title={link?.title}>
      <ImageVideo imageVideo={image} ratio={ratio} externalPlaying={playing} setExternalPlaying={setPlaying} loop />
      <Inner playing={playing}>
        <StyledContainer collapse={"lg>"}>
          <Content top={top} bottom={bottom} left={left} right={right}>
            <TextButtonsGrid content={content} />
          </Content>
          {sideText && <SideText>{sideText}</SideText>}
        </StyledContainer>
      </Inner>
    </Outer>
  )
)
