import tw, { styled } from "twin.macro"

export const SectionWrapper = styled.div`
  ${tw`mx-auto relative`}
  ${({ maxWidth }) => {
    switch (maxWidth) {
      case "sm":
        return tw`max-w-72`
      case "md":
        return tw`max-w-96`
      case "lg":
        return tw`max-w-120`
      case "xl":
        return tw`max-w-xl`
      case "full":
        return tw`max-w-full`
      default:
        return tw`max-w-xl`
    }
  }}
  ${({ horizontalSpacing }) => {
    switch (horizontalSpacing) {
      case "sm":
        return tw`px-1 md:px-3`
      case "md":
        return tw`px-2 md:px-3`
      case "lg":
        return tw`px-2 md:px-4`
      case "none":
        return tw`px-0`
      default:
        return tw`px-2 md:px-3`
    }
  }}
    ${({ verticalSpacing }) => {
      switch (verticalSpacing) {
        case "sm":
          return tw`py-1 md:py-3`
        case "md":
          return tw`py-2 md:py-4`
        case "lg":
          return tw`py-3 md:py-6`
        case "none":
          return tw`py-0`
        default:
          return tw`py-0`
      }
    }}

  ${({ topPaddingSpacing }) => {
    switch (topPaddingSpacing) {
      case "sm":
        return tw`pt-1 md:pt-2`
      case "md":
        return tw`pt-2 md:pt-4`
      case "desktopMd":
        return tw`pt-0 md:pt-4`
      case "lg":
        return tw`pt-3 md:pt-6`
      case "none":
        return tw`pt-0`
      default:
        return tw`pt-0`
    }
  }}

  ${({ bottomPaddingSpacing }) => {
    switch (bottomPaddingSpacing) {
      case "sm":
        return tw`pb-1 md:pb-2`
      case "md":
        return tw`pb-2 md:pb-4`
      case "desktopMd":
        return tw`pb-0 md:pb-4`
      case "lg":
        return tw`pb-3 md:pb-6`
      case "none":
        return tw`pb-0`
      default:
        return tw`pb-0`
    }
  }}  

  ${({ hideDesktop }) => {
    return hideDesktop === "hide" ? tw`md:hidden` : null
  }}

  ${({ hideMobile }) => {
    return hideMobile === "hide" ? tw`hidden md:block` : null
  }}  
`
