import React from "react"

import { withSections } from "./withSections"
import { Section } from "./Section"

export const Sections = React.memo(withSections(
  ({ content, ...props }): JSX.Element =>
    content
      ?.filter(section => !!section)
      ?.map(section => (
        <Section key={section._key} appearance={section?.appearance || section?.settings}>
          <section.component {...props} {...section} />
        </Section>
      )) || null
))
