import tw, { styled, css } from "twin.macro"
import { Body } from "../../Styled/Text"

export const Outer = tw.div`
  relative
`

export const Wrapper = styled.div`
  ${({ items }) => {
    items.length === 1
      ? css`
          display: block;
        `
      : tw`flex flex-col md:flex-row`
  }}
`

export const Explore = styled(Body)`
  ${tw`mb-0-4 md:mb-1-6`}
`

export const ExploreWrapper = tw.button`
  absolute bottom-0-4 md:bottom-2-8 inset-x-0 mx-auto flex flex-col items-center text-black 
`
